/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import { Link, animateScroll as scroll } from "react-scroll";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <Link
        activeClass="active"
        to="ourServices"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
      >
        <ListItem className={classes.listItem}>
          <Button color="transparent" className={classes.navLink}>
            Our Services
          </Button>
        </ListItem>
      </Link>
      <Link
        activeClass="active"
        to="gallery"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
      >
        <ListItem className={classes.listItem}>
          <Button color="transparent" className={classes.navLink}>
            Gallery
          </Button>
        </ListItem>
      </Link>
      <ListItem className={classes.listItem}>
        {/* <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        > */}
          {/* <Button
            color="transparent"
            href="https://www.facebook.com/lakelivingstonkayak"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button> */}
        {/* </Tooltip> */}
      </ListItem>
    </List>
  );
}
