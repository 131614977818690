import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
import shuttle from "assets/img/1x/shuttle.png";
import kayak from "assets/img/1x/kayak.png";
import picture1 from "assets/img/pictures/1.png";
import picture2 from "assets/img/pictures/2.png";
import picture3 from "assets/img/pictures/3.png";
import picture4 from "assets/img/pictures/4.png";
import picture5 from "assets/img/pictures/5.png";
import picture6 from "assets/img/pictures/6.png";
import picture7 from "assets/img/pictures/7.png";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title} id="ourServices">
            Our Services
          </h2>
          <h5 className={classes.description}>
            We provide kayak rental and shuttle service for Lake Livingston and
            the Trinity River.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={shuttle} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Kayak Shuttle Service
                <br />
                <small className={classes.smallTitle}>
                  30 minutes and up · Price varies
                </small>
              </h4>
              <CardBody>
                {/* <p className={classes.description}>
                  You can write here details about one of your services. You can
                  give more details about what they do.
                </p> */}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={kayak} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Kayak Rental
                <br />
                <small className={classes.smallTitle}>
                  1 hour and up · Price varies
                </small>
              </h4>
              <CardBody>
                {/* <p className={classes.description}>
                  You can write here details about one of your services. You can
                  give more details about what they do.
                </p> */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <h3 className={classes.cardTitle}>346-463-2626</h3>
        <h4 className={classes.smallTitle}>Call us to book now!</h4>

        {/* <h4 className={classes.smallTitle}>
          Or head over to our Facebook page.
        </h4> */}

        <div style={{ color: "#000" }}>
          {/* <Tooltip
            id="instagram-facebook"
            title="Follow us on facebook"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              href="https://www.facebook.com/lakelivingstonkayak"
              target="_blank"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-facebook"} />
            </Button>
          </Tooltip> */}
        </div>
      </div>
      <div
        className={classes.section}
        style={{ paddingBottom: 10 }}
        id="gallery"
      >
        <GridContainer spacing={2}>
          <GridItem xs={12} sm={12} md={6}>
            <img src={picture1} className={classes.gridPictures} alt="..." />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <img src={picture2} className={classes.gridPictures} alt="..." />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <img src={picture3} className={classes.gridPictures} alt="..." />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <img src={picture4} className={classes.gridPictures} alt="..." />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <img src={picture5} className={classes.gridPictures} alt="..." />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <img src={picture6} className={classes.gridPictures} alt="..." />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <img src={picture7} className={classes.map} alt="..." />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
